<template>
	<div :style="menuBaseStyle">
		<v-row class="text-center">
			<v-col cols="3" class="d-flex align-center justify-center">

				<v-menu transition="scroll-y-transition">
					<template v-slot:activator="{ on, attrs }">
						<v-icon v-bind="attrs" v-on="on">fa fa-bars</v-icon>
					</template>

					<v-list min-width="300">

						<v-list-item @click="navigateToHome">
							<v-list-item-icon class="justify-center">
								<v-icon class="small">fa fa-home</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title>Home</v-list-item-title>
							</v-list-item-content>
						</v-list-item>

						<v-list-item @click="navigateToLogin" v-if="!authenticated">
							<v-list-item-icon class="justify-center">
								<v-icon class="small">fa fa-lock</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title>Login</v-list-item-title>
							</v-list-item-content>
						</v-list-item>


						<v-list-item @click="navigateToInformationRequest">
							<v-list-item-icon class="justify-center">
								<v-icon class="small">fa fa-info</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title>Informatie aanvragen</v-list-item-title>
							</v-list-item-content>
						</v-list-item>

						<v-list-item @click="navigateToPrivacy">
							<v-list-item-icon class="justify-center">
								<v-icon class="small">fa fa-user-secret</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title>Privacy</v-list-item-title>
							</v-list-item-content>
						</v-list-item>

						<v-list-item @click="navigateToAboutUs">
							<v-list-item-icon class="justify-center">
								<v-icon class="small">fa fa-address-card</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title>Over ons</v-list-item-title>
							</v-list-item-content>
						</v-list-item>

						<v-list-item @click="navigateToWebsite">
							<v-list-item-icon class="justify-center">
								<v-icon class="small">fa fa-external-link-alt</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title>Bezoek website</v-list-item-title>
							</v-list-item-content>
						</v-list-item>

						<v-list-item @click="reloadApp">
							<v-list-item-icon class="justify-center">
								<v-icon class="small">fas fa-redo</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title>Herlaad app</v-list-item-title>
							</v-list-item-content>
						</v-list-item>

						<v-list-item @click="navigateToLogout" v-if="authenticated">
							<v-list-item-icon class="justify-center">
								<v-icon class="small">fa fa-sign-out-alt</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title>Loguit</v-list-item-title>
							</v-list-item-content>
						</v-list-item>

					</v-list>
				</v-menu>
			</v-col>
			<v-col cols="6">
				<v-img
					alt="Humanwise Logo"
					:style="menuBaseLogoStyle"
					contain
					src="../assets/logo.svg"
					transition="scale-transition"
					max-height="60"
					@click="navigateToHome"
				/>
			</v-col>
			<v-col cols="3" class="d-flex align-center justify-center">
				<v-icon @click="navigateToHome">fa fa-home</v-icon>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
	name: "menuDashboard",
	data() {
		return {
			menuBaseStyle: {
				'background-color': this.$vuetify.theme.themes.light.white,
				'height': '80px',
				'padding-top': '10px',
				'position': 'fixed',
				'top': '5px',
				'width': '100%',
				'z-index': '1000',
			},
			menuBaseLogoStyle: {
				'height': '80px'
			}
		}
	},
	computed: {
		...mapGetters({
			authenticated: "auth/authenticated"
		}),
	},
	methods: {
		...mapActions({
			signOut: 'auth/signOut'
		}),
		navigateToHome() {
			if (!this.authenticated) {
				this.$router.push({name: 'home'})
			} else {
				this.$router.push({name: 'dashboardHome'})
			}
		},
		navigateToLogin() {
			this.$router.push({name: 'login'})
		},
		navigateToLogout() {
			this.signOut().then(() => {
				this.$router.push({name: 'home'})
			})

		},
		navigateToInformationRequest() {
			this.$router.push({name: 'informationRequest'})
		},
		navigateToPrivacy() {
			this.$router.push({name: 'privacyStatement'})
		},
		navigateToAboutUs() {
			this.$router.push({name: 'aboutUs'})
		},
		navigateToWebsite() {
			window.open('https://humanwise.nl', '_blank');
		},
		reloadApp() {
			window.location.reload(true);
		},
	}
};
</script>

<style scoped>
.v-icon {
	color: #004572 !important;
	font-size: 30px !important;
}

.v-icon.small {
	font-size: 20px !important;
}

.v-list {
	background-color: white !important;
	color: #004572 !important;
}

.v-list-item {
	color: #004572 !important;
}

.theme--dark.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
	color: #004572 !important;
}

.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
	color: #004572 !important;
}
</style>